import { render, staticRenderFns } from "./health-service-uptake.vue?vue&type=template&id=f30ae618&scoped=true"
import script from "./health-service-uptake.vue?vue&type=script&lang=js"
export * from "./health-service-uptake.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f30ae618",
  null
  
)

export default component.exports